<ng-container *ngIf="headerService.isTestingBar | async">
    <div class="testing">
        <div class="testing__text">
            <span med-typography textStyle="body-m">{{currentLanguage === 'de' ? 'Bitte beachte: Dies ist eine Beta-Version der Medatus-Plattform, die derzeit intensiv entwickelt und getestet wird!' : 'Please note: this is a beta version view of the Medatus platform that is currently under heavy development and testing!' }}</span>
            <span med-icon iconName="state-off" iconColor="white" (click)="headerService.isTestingBar.next(false)"></span>
        </div>
    </div>
    <div class="testing__gap"></div>
</ng-container>

<div class="wrapper" [@routeChangeAnimation]="routeAnimation">
    <med-header></med-header>
</div>
<div [@routeChangeAnimation]="routeAnimation">
    <router-outlet></router-outlet>
</div>
<div class="wrapper">
    <med-footer></med-footer>
</div>
