import { Component, Input } from '@angular/core';
import { MedLoaderSize, MedLoaderStrokeWidth, MedLoaderTrail } from './med-loader.types';

@Component({
  selector: 'med-loader',
  template: `
    <svg
      class="spinner"
      viewBox="0 0 50 50"
      [ngStyle]="{
        'width.px': loaderSize,
        'height.px': loaderSize
      }"
    >
      <circle
        class="path"
        [ngClass]="{
          primary: trailType === 'primary',
          warn: trailType === 'warn',
          neutral: trailType === 'neutral',
          white: trailType === 'white',
        }"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        [attr.stroke-width]="trailWidth"
      ></circle>
    </svg>
  `,
  styleUrls: ['med-loader.component.scss'],
})
export class MedLoaderComponent {
  @Input() loaderSize: MedLoaderSize = 60;
  @Input() trailWidth: MedLoaderStrokeWidth = 4;
  @Input() trailType: MedLoaderTrail = 'primary';
}
