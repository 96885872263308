import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  isHeaderSticky: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPageStickyHeaderDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isTestingBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  checkIfStickyHeaderAllowed(url: string): void {
    const splittedUrl = url.split('/').slice(1);
    const allowedPages = ['', 'how-works', 'about', 'become-provider', 'pricing'];
    const showStickyHeader = splittedUrl.some((urlParam) => allowedPages.includes(urlParam));

    this.isPageStickyHeaderDisplayed.next(showStickyHeader);
  }
}
