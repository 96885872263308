import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedTextButtonComponent } from './med-text-button.component';
import { MedTextButtonGroupComponent } from './med-text-button-group.component';
import { MedIconModule } from '../med-icon/med-icon.module';
import { MedTypographyModule } from '../med-typography/med-typography.module';


@NgModule({
  declarations: [MedTextButtonComponent, MedTextButtonGroupComponent],
  imports: [CommonModule, MedTypographyModule, MedIconModule],
  exports: [MedTextButtonComponent, MedTextButtonGroupComponent],
})
export class MedTextButtonModule {}
