import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { FOOTER_DATA, FOOTER_MEDIA } from './data/footer.data';
import { FooterData, FooterMedia } from './interfaces/footer.interface';
import { MedContentLocalization, LocalizationType } from 'src/app/common/models/med-localization.service.types';
import { MedLocalizationService } from 'src/app/common/services/med-localization.service';

@Component({
  selector: 'med-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  content: MedContentLocalization<FooterData> = FOOTER_DATA;
  media: FooterMedia[] = FOOTER_MEDIA;
  activeContent: FooterData = this.content.en;

  currentYear = new Date().getFullYear();

  subscription?: Subscription;

  constructor(private readonly locService: MedLocalizationService) {}

  ngOnInit(): void {
    this.subscription = this.locService.localizationSubject.subscribe(
      (lang: LocalizationType) => (this.activeContent = this.content[lang]),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
