import { MedContentLocalization } from 'src/app/common/models/med-localization.service.types';
import { FooterData, FooterMedia } from '../interfaces/footer.interface';

export const FOOTER_DATA: MedContentLocalization<FooterData> = {
  en: {
    title: 'Your health. Your search. Your Medatus.',

    lists: [
      {
        heading: 'Medatus',
        links: [
          {
            name: 'Start search',
            link: 'search',
          },
          {
            name: 'How it works',
            link: 'how-works',
          },
          {
            name: 'About Medatus',
            link: 'about',
          },
          {
            name: 'Contact',
            link: 'contact',
          },
        ],
      },
      {
        heading: 'Services',
        links: [
          {
            name: 'Become a provider',
            link: 'become-provider',
          },
        ],
      },
    ],

    copyrightTitle: '© Medatus GmbH',
    copyrightLinks: [
      {
        name: 'Terms of Use',
        link: 'terms-of-use',
      },
      {
        name: 'Privacy Policy',
        link: 'data-privacy',
      },
      {
        name: 'Imprint',
        link: 'imprint',
      },
    ],
  },
  de: {
    title: 'Deine Gesundheit. Deine Suche. Dein Medatus.',

    lists: [
      {
        heading: 'Medatus',
        links: [
          {
            name: 'Suche starten',
            link: 'search',
          },
          {
            name: 'So funktioniert´s',
            link: 'how-works',
          },
          {
            name: 'Über uns',
            link: 'about',
          },
          {
            name: 'Kontakt',
            link: 'contact',
          },
        ],
      },
      {
        heading: 'Services',
        links: [
          {
            name: 'Anbieter werden',
            link: 'become-provider',
          },
        ],
      },
    ],

    copyrightTitle: '© Medatus GmbH',
    copyrightLinks: [
      {
        name: 'AGB',
        link: 'terms-of-use',
      },
      {
        name: 'Datenschutz',
        link: 'data-privacy',
      },
      {
        name: 'Impressum',
        link: 'imprint',
      },
    ],
  },
};

export const FOOTER_MEDIA: FooterMedia[] = [
  {
    name: 'instagram',
    link: 'https://www.instagram.com/medatus_de/',
    label: 'Visit Medatus instagram',
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/medatus/',
    label: 'Connect to Medatus facebook',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/medatus/',
    label: 'Check Medatus linkedin',
  },
];
