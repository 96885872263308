import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  isDevMode,
} from '@angular/core';
import { MedIconName, MedIconSize, MedIconColor } from './med-icon.types';

@Component({
  selector: 'span[med-icon]',
  exportAs: 'medIcon',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'med-icon',
  },
  template: `
    <img src="{{ mediaURL }}icons/{{ this.iconName }}.svg" #img />
    <ng-content></ng-content>
  `,
  styleUrls: ['./med-icon.component.scss'],
})
export class MedIconComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild('img') img!: ElementRef;

  cacheClassName: string | null = null;
  @Input() iconName: MedIconName | null = null;
  @Input() iconSize: MedIconSize | null = null;
  @Input() iconColor: MedIconColor | null = null;
  @Input() iconSpin = false;
  @Input() iconRotate = 0;

  mediaURL?: string;

  constructor(public elementRef: ElementRef, public readonly renderer: Renderer2) {}

  ngOnInit(): void {
    if (isDevMode()) {
      this.mediaURL = 'https://media.medatus.dev/';
    } else {
      this.mediaURL = 'https://media.medatus.de/';
    }

    if (!this.iconName) this.iconName = 'ukraine';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { iconColor } = changes;

    if (iconColor && !iconColor.isFirstChange()) {
      this.img.nativeElement.className = '';
      if (this.iconColor) this.setColor(this.iconColor);
    }
  }

  ngAfterViewInit(): void {
    this.setIcon();
  }

  private setIcon(): void {
    this.setClassName();
    this.setIconData(this.elementRef.nativeElement);
    this.setSize(this.img.nativeElement);
    if (this.iconColor) this.setColor(this.iconColor);
    this.handleSpin(this.elementRef.nativeElement);
    this.handleRotation(this.elementRef.nativeElement);
  }

  private setClassName(): void {
    if (this.cacheClassName) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.cacheClassName);
    }
    this.cacheClassName = `med-icon-${this.iconName}`.toLowerCase();
    this.renderer.addClass(this.elementRef.nativeElement, this.cacheClassName);
  }

  private setIconData(icon: HTMLElement): void {
    this.renderer.setAttribute(icon, 'data-icon', this.iconName as string);
    this.renderer.setAttribute(icon, 'aria-hidden', 'true');
  }

  private handleSpin(icon: HTMLElement): void {
    if (this.iconSpin || this.iconName === 'loader') {
      this.renderer.addClass(icon, 'med-icon-spin');
    } else {
      this.renderer.removeClass(icon, 'med-icon-spin');
    }
  }

  private handleRotation(icon: HTMLElement): void {
    if (this.iconRotate) {
      this.renderer.setAttribute(
        icon,
        'style',
        `transform: rotate(${this.iconRotate}deg); -webkit-transform: rotate(${this.iconRotate}deg); -moz-transform: rotate(${this.iconRotate}deg)`,
      );
    } else {
      this.renderer.removeAttribute(icon, 'style');
    }
  }

  private setSize(icon: HTMLElement): void {
    if (this.iconSize) {
      this.renderer.setStyle(icon, 'width', `${this.iconSize}px`);
      this.renderer.setStyle(icon, 'height', `${this.iconSize}px`);
    }
  }

  private setColor(color: string): void {
    this.img.nativeElement.classList.add(`icon-${color}`);
  }
}
