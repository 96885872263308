import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisplayType } from './types';

@Injectable({
  providedIn: 'root',
})
export class MedBreakpointService {
  displayType: BehaviorSubject<DisplayType> = new BehaviorSubject<DisplayType>(null);

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xsmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xlarge'],
  ]);

  constructor(private breakPointObserver: BreakpointObserver) {
    this.breakPointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((state: BreakpointState) => {
        for (const query of Object.keys(state.breakpoints)) {
          if (state.breakpoints[query]) {
            this.displayType.next(this.displayNameMap.get(query) as DisplayType);
          }
        }
      });
  }
}
