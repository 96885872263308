import { MedContentLocalization } from '../models/med-localization.service.types';
import { MetaData } from '../models/meta-data.interface';

export const META_DATA: MedContentLocalization<MetaData[]> = {
  en: [
    {
      route: '/',
      title: 'Medatus - Your health. Your search.',
      description:
        'Sick? Want to live healthier? Find out who or what can really help you. Try it out now! Free of charge ✓ Secure ✓ Anonymous.',
    },
    {
      route: '/search',
      title: 'Start your personal health search',
      description:
        "Whether you're sick or healthy, all help starts with the right search. Find out who or what can help you.",
    },
    {
      route: '/how-works',
      title: 'How to use Medatus',
      description:
        'Start the search. Answer simple questions and find the right healthcare services and specialists for you.',
    },
    {
      route: '/about',
      title: 'About us',
      description: 'Our mission is to provide modern healthcare to all. Learn more about us and our history.',
    },
    {
      route: '/contact',
      title: 'Contact',
      description: 'Do you have questions or problems using Medatus? Contact us and our team will get back to you.',
    },
    {
      route: '/become-provider',
      title: 'Become a provider',
      description:
        'Reach more suitbale patients and customers for you, your practice or your business with our matching system.',
    },
  ],
  de: [
    {
      route: '/',
      title: 'Medatus - Deine Gesundheit. Deine Suche.',
      description:
        "Krank? Gesund leben? Erfahre, wer oder was dir helfen kann - genau auf dich abgestimmt. Probier's jetzt aus! ✓ Kostenlos ✓ Sicher ✓ Anonym.",
    },
    {
      route: '/search',
      title: 'Starte deine persönliche Gesundheitssuche',
      description:
        'Egal, ob krank oder topfit - jede Hilfe beginnt mit der richtigen Suche. Finde heraus, wer oder was dir helfen kann.',
    },
    {
      route: '/how-works',
      title: 'So funktioniert Medatus',
      description:
        'Starte die Suche. Beantworte einfache Fragen und finde für dich passende Gesundheitsleistungen sowie Fachleute von A bis Z.',
    },
    {
      route: '/about',
      title: 'Über uns',
      description:
        'Unsere Mission ist es, allen eine moderne Gesundheitsversorgung zu ermöglichen. Erfahre mehr über uns und unsere Geschichte.',
    },
    {
      route: '/contact',
      title: 'Kontakt',
      description:
        'Du hast Fragen oder Probleme bei der Verwendung von Medatus? Nimm Kontakt zu uns auf und unser Team meldet sich bei dir.',
    },
    {
      route: '/become-provider',
      title: 'Anbieter werden',
      description:
        'Erreiche mit unserem Matching-System mehr passende Patienten und Kunden für dich, deine Praxis oder dein Unternehmen.',
    },
  ],
};
