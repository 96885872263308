import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { MedIconComponent } from '../med-icon/med-icon.component';
import { MedIconName } from '../med-icon/med-icon.types';
import { MedTypographyType } from '../med-typography/med-typography.component';

export type MedTextButtonType = 'primary' | 'default' | 'neutral' | 'black' | null;

@Component({
  selector: 'a[med-text-button], button[med-text-button]',
  exportAs: 'medTextButton',
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-container *ngIf="btnPrefixIcon">
      <span med-icon [iconName]="btnPrefixIcon"></span>
    </ng-container>
    <span med-typography [textStyle]="setTypography()"><ng-content></ng-content></span>

    <div class="text-button-suffix" *ngIf="btnSuffixIcon" (click)="onSuffixClick()">
      <span med-icon [iconName]="btnSuffixIcon" *ngIf="btnSuffixIcon" #suffixIcon></span>
    </div>
  `,
  host: {
    class: 'med-text-btn',
    '[class.med-text-btn-primary]': `btnType === 'primary'`,
    '[class.med-text-btn-neutral]': `btnType === 'neutral'`,
    '[class.med-text-btn-black]': `btnType === 'black'`,
    '[class.med-text-btn-default]': `btnType === 'default'`,
    '[class.med-text-btn-icon]': `btnPrefixIcon || btnSuffixIcon`,
    '[attr.tabindex]': 'disabled ? -1 : (tabIndex === null ? null : tabIndex)',
    '[attr.disabled]': 'disabled || null',
    '[class.active]': `active === true`,
    '[class.disabled]': `disabled === true`,
  },
  styleUrls: ['./med-text-button.component.scss'],
})
export class MedTextButtonComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() btnType: MedTextButtonType = 'default';
  @Input() disabled = false;
  @Input() btnPrefixIcon: MedIconName | null = null;
  @Input() btnSuffixIcon: MedIconName | null = null;
  @Input() btnTypography: MedTypographyType = 'button-l';
  @Input() tabIndex: number | string | null = 0;
  @Input() active = false;
  @ViewChild('suffixIcon') suffixIcon!: MedIconComponent;

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter' && (event.target as HTMLElement).localName == 'a') {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.target?.dispatchEvent(new Event('click'));
    }
  }

  private elements: any;
  private destroy$ = new Subject<void>();

  constructor(private ngZone: NgZone, public elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // To apply prevent-default() and stop-propagation() when the element is disabled
    this.ngZone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(this.elementRef.nativeElement, 'click', { capture: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
          } else {
            // Handle Menu 'Active' Class on Click
            this.elements.forEach((menuItem: any, index: number) => {
              this.renderer.removeClass(this.elements[index], 'active');
              if (menuItem == this.elementRef.nativeElement) this.renderer.addClass(menuItem, 'active');
            });
          }
        });
    });
  }

  ngAfterViewInit(): void {
    this.elements = document.querySelectorAll('.med-text-btn');
  }

  onSuffixClick() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTypography(): MedTypographyType {
    return this.btnTypography;
  }
}
