import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OutsideClickDirective } from './common/directives/outside-click/outside-click.directive';
import { MedDropdownModule } from './common/med-components/med-dropdown/med-dropdown.module';
import { MedFilledButtonModule } from './common/med-components/med-filled-button/med-filled-button.module';
import { MedIconModule } from './common/med-components/med-icon/med-icon.module';
import { MedTextButtonModule } from './common/med-components/med-text-button/med-text-button.module';
import { MedTypographyModule } from './common/med-components/med-typography/med-typography.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    OutsideClickDirective,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,

    MedTextButtonModule,
    MedTypographyModule,
    MedFilledButtonModule,
    MedIconModule,
    MedDropdownModule,

    AppRoutingModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
