import { MedContentLocalization } from 'src/app/common/models/med-localization.service.types';
import { HeaderData } from '../interfaces/header.interface';

export const HEADER_DATA: MedContentLocalization<HeaderData> = {
  en: {
    menuLinks: [
      {
        text: 'Start search',
        link: 'search',
      },
      {
        text: 'How it works',
        link: 'how-works',
      },
      {
        text: 'About us',
        link: 'about',
      },
      {
        text: 'Contact',
        link: 'contact',
      },
    ],
    providerBtn: 'Become a provider',
    providerOptions: [
      {
        title: 'Become a provider',
        description: 'Gain more suitable patients and customers for you. With our Matching.',
        icon: 'provider-24px',
        route: '/become-provider',
      },
      {
        title: 'Pricing',
        description: 'Pricing is based on the number of healthcare services you want to offer.',
        icon: 'pricing-24px',
        route: '/pricing',
      },
    ],
    loginBtn: 'Log in',
    signupBtn: 'Sign up',
  },

  de: {
    menuLinks: [
      {
        text: 'Suche starten',
        link: 'search',
      },
      {
        text: 'So funktioniert´s',
        link: 'how-works',
      },
      {
        text: 'Über uns',
        link: 'about',
      },
      {
        text: 'Kontakt',
        link: 'contact',
      },
    ],
    providerBtn: 'Anbieter werden',
    providerOptions: [
      {
        title: 'Anbieter werden',
        description:
          'Gewinne mit Medatus mehr passende Patienten und Kunden für dich.',
        icon: 'provider-24px',
        route: '/become-provider',
      },
      {
        title: 'Preise',
        description: 'Die Preise richten sich nach der Anzahl der Leistungen, die du anbietest.',
        icon: 'pricing-24px',
        route: '/pricing',
      },
    ],
    loginBtn: 'Einloggen',
    signupBtn: 'Registrieren',
  },
};
