import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { MedIconName } from '../med-icon/med-icon.types';
import { MedTypographyType } from '../med-typography/med-typography.component';

export type MedTagStatusColor = 'default' | 'primary' | 'destructive' | 'success';
export type MedTagMode = 'default' | 'closeable' | 'icon' | 'checkable' | 'category';

@Component({
  selector: 'med-tag',
  exportAs: 'medTag',
  encapsulation: ViewEncapsulation.None,
  template: `
    <span med-typography [textStyle]="textStyle ? textStyle : setTypography()">
      <span *ngIf="tagText" [innerHTML]="tagText"></span>
      <ng-content></ng-content>
    </span>
    <span
      med-icon
      [iconName]="tagIcon"
      class="tag-icon"
      *ngIf="tagMode === 'icon' || (tagMode === 'checkable' && tagIcon)"
    ></span>
    <span
      med-icon
      [iconName]="tagIcon"
      class="tag-icon tag-close-icon"
      *ngIf="tagMode === 'closeable'"
      (click)="closeTag($event)"
    ></span>
  `,
  host: {
    class: 'med-tag',
    '[class.med-tag-default]': `tagMode === 'default'`,
    '[class.med-tag-closeable]': `tagMode === 'closeable'`,
    '[class.med-tag-iconed]': `tagMode === 'icon' || (tagMode === 'checkable' && tagIcon)`,
    '[class.med-tag-checkable]': `tagMode === 'checkable'`,
    '[class.med-tag-category]': `tagMode === 'category'`,
    '[class.med-tag-checkable-checked]': `tagChecked === true`,
    '[class.med-tag-disabled]': `disabled === true`,
    '[class.med-tag-color-primary]': `tagColor === 'primary'`,
    '[class.med-tag-color-destructive]': `tagColor === 'destructive'`,
    '[class.med-tag-color-success]': `tagColor === 'success'`,
    '[class.med-tag-static]': `tagStatic`,
    '[attr.tabindex]': 'disabled ? -1 : (tabIndex === null ? null : tabIndex)',
    '[attr.disabled]': 'disabled || null',
    '(click)': 'updateCheckedStatus()',
  },
  styleUrls: ['./med-tag.component.scss'],
})
export class MedTagComponent implements OnInit {
  @Input() tagMode: MedTagMode = 'default';

  @Input() tagColor?: MedTagStatusColor | null = null;

  @Input() textStyle?: MedTypographyType;

  @Input() tagChecked = false;
  @Input() tagStatic = false;

  @Input() tagIcon: MedIconName | null = null;

  @Input() tagId = '';

  @Input() tagText: string | null = null;

  @Input() disabled = false;

  @Input() tabIndex: number | string | null = 0;

  @Output() readonly tagOnClose = new EventEmitter<MouseEvent | KeyboardEvent>();

  @Output() tagOnDelete: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly tagCheckedChange = new EventEmitter<boolean>();

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.target?.dispatchEvent(new Event('click'));
    }
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.stopImmediatePropagation();
      this.closeTag(event);
    }
  }

  constructor(private renderer: Renderer2, public elementRef: ElementRef) {}

  ngOnInit() {
    if (this.tagMode === 'checkable' && !this.tagColor) {
      this.tagColor = 'default';
    } else if (this.tagMode === 'closeable') {
      this.tagIcon = 'close';
    }
  }

  updateCheckedStatus(): void {
    if (this.tagMode === 'checkable') {
      this.tagChecked = !this.tagChecked;
      this.tagCheckedChange.emit(this.tagChecked);
    }
  }

  closeTag(e?: MouseEvent | KeyboardEvent): void {
    this.tagOnDelete.emit(this.tagText ?? '');
    this.tagOnClose.emit(e);
    if (!e?.defaultPrevented && this.tagMode === 'closeable') {
      this.renderer.removeChild(this.renderer.parentNode(this.elementRef.nativeElement), this.elementRef.nativeElement);
    } else {
      if (!e?.defaultPrevented) {
        this.renderer.removeChild(
          this.renderer.parentNode(this.elementRef.nativeElement),
          this.elementRef.nativeElement,
        );
      }
    }
  }

  setTypography(): MedTypographyType {
    if (this.tagMode === 'checkable') {
      return 'subtitle-xs';
    } else {
      return 'subtitle-s';
    }
  }
}
