<div class="footer section">
    <div class="footer__overlay"></div>
    <div class="footer__logo">
        <a class="header__logo" routerLink="" aria-label="Return to home page">
            <img src="../../../../assets/medatus/medatus-logo.svg" alt="">
        </a>
        <div class="footer__media">
            <a href="{{media.link}}" *ngFor="let media of media" [attr.aria-label]="media.label">
                <span med-icon [iconName]="media.name"></span>
            </a>
        </div>
    </div>
    
    <div class="footer__content">
        <div class="footer__title">
            <span med-typography textStyle="website-heading-xs">{{ activeContent.title }}</span>
        </div>
        <div class="footer__list-container">
            <div class="footer__list-wrapper" *ngFor="let list of activeContent.lists">
                <span class="footer__list-header font-neutral-700" med-typography textStyle="website-subtitle-s">{{ list.heading }}</span>
                <ul class="footer__list">
                    <li med-typography textStyle="website-subtitle-m" *ngFor="let linkList of list.links">
                        <a routerLink="/{{linkList.link}}">{{ linkList.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <hr class="footer__horizontal-line">

    <div class="footer__copyright">
        <span class="font-neutral-600" med-typography textStyle="website-body-m">
            {{ activeContent.copyrightTitle }} {{ currentYear }}
        </span>
        <div class="footer__policy">
            <span med-typography textStyle="website-subtitle-m" *ngFor="let link of activeContent.copyrightLinks">
                <a routerLink="/{{link.link}}">{{ link.name }}</a>
            </span>
        </div>
    </div>
</div>
