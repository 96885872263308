import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  Directive,
  ElementRef,
  EnvironmentInjector,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MedDropdownComponent } from './med-dropdown.component';
import { DropdownData, DropdownInfoText } from './med-dropdown.types';
import { MedTagComponent } from '../med-tag/med-tag.component';

@Directive({
  selector: '[medDropdown]',
  host: {
    class: 'med-dropdown-trigger-element',
    'aria-autocomplete': 'list',
    type: 'button',
  },
})
export class MedDropdownDirective implements OnInit, OnChanges, OnDestroy {
  private dpdComponent!: MedDropdownComponent;
  private dpdComponentRef?: ComponentRef<MedDropdownComponent>;
  private dpdTagComponentRefs: ComponentRef<MedTagComponent>[] = [];

  @Input() dpdTagCloseable?: boolean;
  @Input() dpdTagMode = false;
  @Input() dpdTagTarget?: HTMLElement;
  @Input() dpdData: DropdownData[] = [];
  @Input() dpdMultipleSelect = false;
  @Input() dpdSort: 'alphabetical' | 'simple' = 'simple';
  @Input() dpdOpen = false;
  @Output() dpdOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() contentValue = '';
  @Output() contentValueChange = new EventEmitter<string>();

  @Input() dpdInfoText: DropdownInfoText = {
    notFoundMessage: '',
    infoMessage: '',
  };
  @Output() onSelectionChange: EventEmitter<DropdownData[]> = new EventEmitter<DropdownData[]>();
  @Output() onSelectionChangeSelected: EventEmitter<DropdownData[]> = new EventEmitter<DropdownData[]>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  /* show dropdown component */
  @HostListener('document:click', ['$event']) onClickOutside(event: PointerEvent) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.dpdComponent.isVisible = !this.dpdComponent.isVisible;
      this.dpdOpenChange.emit(this.dpdComponent.isVisible);
    } else if (
      !this.elementRef.nativeElement.contains(event.target) &&
      !this.dpdComponentRef?.location.nativeElement.contains(event.target)
    ) {
      this.dpdComponent.isVisible = false;
      this.dpdOpenChange.emit(this.dpdComponent.isVisible);
    }
  }

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    private containerRef: ViewContainerRef,
    public elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if (this.dpdTagMode && !this.dpdTagTarget) {
      this.dpdTagMode = false;
      console.warn(`
      Tag mode only works if a tag reference is provided! 
      For further information see documentation for Medatus Dropdown Module.
      
      dpdTagMode is set to false now and falling back to text strings.`);
    }
    this.createDropdownComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { dpdData, dpdInfoText } = changes;

    if (dpdInfoText && this.dpdComponent) {
      this.dpdComponent.dropdownInfoText = dpdInfoText.currentValue;
    }

    if (dpdData && this.dpdComponent) {
      this.dpdComponent.dpdData = dpdData.currentValue;
    }

    // Setting ContentValue
    if (this.contentValue) {
      this.dpdData.forEach((item: any) => {
        if (item?.text === this.contentValue) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    }
  }

  /* create dropdown component */
  createDropdownComponent(): void {
    this.dpdComponentRef = createComponent<MedDropdownComponent>(MedDropdownComponent, {
      environmentInjector: this.injector,
    });
    this.dpdComponent = this.dpdComponentRef.instance;
    this.dpdComponent.dpdData = this.dpdData;
    this.dpdComponent.dpdMultipleSelect = this.dpdMultipleSelect;
    this.dpdComponent.dropdownInfoText = this.dpdInfoText;
    this.dpdComponent.isVisible = this.dpdOpen;
    this.dpdComponent.onDropDownItemSelection.subscribe((dpdData: DropdownData[]) => {
      if (dpdData) {
        this.dpdData = dpdData;
        this.onSelectionChange.emit(this.dpdData);
        this.onSelectionChangeSelected.emit(this.getItemSelection());
      }
    });
    this.dpdComponent.onDropDownItemChange.pipe(takeUntil(this.destroy$)).subscribe((dpdData: DropdownData) => {
      if (this.dpdTagMode) {
        if (this.dpdMultipleSelect) {
          if (!dpdData.checked) {
            /* we have to close tags manually */
            this.removeTagComponentByName(dpdData.text);
          } else {
            /* we create a tag manually */
            this.addTagToView(dpdData.text);
          }
        } else {
          this.removeAllTagComponents();
          if (dpdData.checked) {
            this.addTagToView(dpdData.text);
          }
        }
      }
    });
    this.containerRef.insert(this.dpdComponentRef.hostView);
    // this.appRef.attachView(this.dpdComponentRef.hostView);
  }

  /* create med tag component */
  createMedTagComponent(tagText: string): ComponentRef<MedTagComponent> {
    const tagComponentRef = createComponent<MedTagComponent>(MedTagComponent, { environmentInjector: this.injector });
    const tagComponent = tagComponentRef.instance;
    this.dpdTagComponentRefs.push(tagComponentRef);
    tagComponent.tagMode = this.dpdTagCloseable ? 'closeable' : 'default';
    tagComponent.tagText = tagText;
    /* remove componentRef if tag gets closed */
    tagComponent.tagOnClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dpdTagComponentRefs.forEach((tag: ComponentRef<MedTagComponent>, index: number) => {
        if (tag.instance === tagComponent) {
          this.dpdTagComponentRefs.splice(index, 1);
          this.dpdData.forEach((dpdData: DropdownData) => {
            if (dpdData.text === tag.instance.tagText && dpdData.checked) {
              dpdData.checked = false;
            }
          });
        }
      });
    });
    return tagComponentRef;
  }

  /*
    add a tag to the dpdTagTarget
    will check if tag already exists
  */
  addTagToView(tagText: string): void {
    if (!this.getTagComponentByTagText(tagText)) {
      const tagComponentRef = this.createMedTagComponent(tagText);
      this.dpdTagTarget?.appendChild(tagComponentRef.location.nativeElement);
      this.appRef.attachView(tagComponentRef.hostView);
    }
  }

  /* remove a tag from view */
  removeTagComponentByName(tagText: string): void {
    const tagComponentRef = this.getTagComponentByTagText(tagText);
    tagComponentRef?.instance.closeTag();
  }

  /* get tag component by tag text or undefined */
  getTagComponentByTagText(tagText: string): ComponentRef<MedTagComponent> | undefined {
    for (const tagComponentRef of this.dpdTagComponentRefs) {
      if (tagComponentRef.instance.tagText === tagText) {
        return tagComponentRef;
      }
    }
    return undefined;
  }

  /* remove all tag components */
  removeAllTagComponents(): void {
    this.dpdTagComponentRefs.forEach((tagComponentRef: ComponentRef<MedTagComponent>) => {
      tagComponentRef.instance.closeTag();
    });
  }

  /* get all selected items as array */
  getItemSelection(): DropdownData[] {
    const selection: DropdownData[] = [];
    this.dpdData.forEach((item: DropdownData) => {
      if (item.checked) selection.push(item);
    });
    /* this.dpdComponent.onDropdownItemClick(selection[0]); */
    this.contentValue = selection[0]?.text;
    this.contentValueChange.emit(selection[0]?.text);

    return selection;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
