import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { META_DATA } from './common/data/meta-data';
import { LocalizationType } from './common/models/med-localization.service.types';
import { MedLocalizationService } from './common/services/med-localization.service';
import { SeoService } from './common/services/seo.service';
import { HeaderService } from './common/services/header.service';
import { routeChangeAnimation } from './common/animations/route-change.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeChangeAnimation],
})
export class AppComponent implements OnInit {
  metaData = META_DATA;
  routeAnimation = true;
  currentLanguage: LocalizationType = 'de';

  constructor(
    private router: Router,
    private seoService: SeoService,
    private localizationService: MedLocalizationService,
    public headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.localizationService.localizationSubject.subscribe((lang) => {
      this.currentLanguage = lang;
      this.updateMetaData();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.triggerRouteAnimation();
        this.headerService.checkIfStickyHeaderAllowed(event.url);
        this.seoService.setCanonicalUrl();
        this.updateMetaData();
      }
    });
  }

  updateMetaData() {
    const routeIndex = this.getRouteIndex();

    if (routeIndex >= 0) {
      this.seoService.setMetaData(
        this.metaData[this.currentLanguage][routeIndex].title,
        this.metaData[this.currentLanguage][routeIndex].description,
      );
    }
  }

  getRouteIndex(): number {
    return this.metaData[this.currentLanguage].findIndex((page) => page.route === this.router.url.split('?')[0]);
  }

  triggerRouteAnimation(): boolean {
    return (this.routeAnimation = !this.routeAnimation);
  }
}
