import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./search-flow-pages/search-flow.module').then((m) => m.SearchFlowModule),
  },
  {
    path: 'how-works',
    loadChildren: () => import('./how-works-page/how-works-page.module').then((m) => m.HowWorksPageModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./about-page/about-page.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact-page/contact-page.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'become-provider',
    loadChildren: () =>
      import('./become-provider-page/become-provider-page.module').then((m) => m.BecomeProviderPageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./provider-profile-page/provider-profile-page.module').then((m) => m.ProviderProfilePageModule),
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pricing-page/pricing-page.module').then((m) => m.PricingPageModule),
  },
  {
    path: 'imprint',
    loadChildren: () => import('./imprint-page/imprint-page.module').then((m) => m.ImprintPageModule),
  },
  {
    path: 'data-privacy',
    loadChildren: () => import('./data-privacy-page/data-privacy-page.module').then((m) => m.DataPrivacyPageModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use-page/terms-of-use-page.module').then((m) => m.TermsOfUsePageModule),
  },
  {
    path: '404',
    loadChildren: () => import('./layout/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
