<ng-container *ngIf="(headerService.isHeaderSticky | async) && (headerService.isPageStickyHeaderDisplayed | async)">
    <div class="header__sticky-gap"></div>
</ng-container>

<div class="header" [ngClass]="{'header__sticky': (headerService.isHeaderSticky | async) && (headerService.isPageStickyHeaderDisplayed | async)}" [@fadeInOutAnimation]="{value: (headerService.isHeaderSticky | async) && (headerService.isPageStickyHeaderDisplayed | async), params: {topPosition: (headerService.isTestingBar | async) ? isTablet ? '50px' : isMobile ? '72px' : '32px' : '0'}}" [ngStyle]="{'top': (headerService.isTestingBar | async) ? isTablet ? '50px': isMobile ? '72px' : '32px' : '0'}">
    <ng-container *ngTemplateOutlet="header"></ng-container>
</div>

<ng-template #header>
    <a class="header__logo" routerLink="" aria-label="Home page">
        <img src="../../../assets/medatus/medatus-logo.svg" alt="">
    </a>

    <ng-container *ngIf="!isSmallMonitor; else burgerNav">
        <ng-container *ngTemplateOutlet="menu"></ng-container>
    </ng-container>
</ng-template>

<ng-template #menu>
    <nav class="header__menu">
        <med-text-button-group [isMenuLink]="true" menuLinkType="website" (click)="closeNav()">
            <a routerLink="/{{ activeContent.menuLinks[0].link }}" routerLinkActive="active active-option" med-text-button btnType="primary" btnTypography="website-subtitle-m">{{ activeContent.menuLinks[0].text }}</a>
            <a routerLink="/{{ activeContent.menuLinks[1].link }}" routerLinkActive="active active-option" med-text-button btnType="primary" btnTypography="website-subtitle-m">{{ activeContent.menuLinks[1].text }}</a>
            <a routerLink="/{{ activeContent.menuLinks[2].link }}" routerLinkActive="active active-option" med-text-button btnType="primary" btnTypography="website-subtitle-m">{{ activeContent.menuLinks[2].text }}</a>
            <a routerLink="/{{ activeContent.menuLinks[3].link }}" routerLinkActive="active active-option" med-text-button btnType="primary" btnTypography="website-subtitle-m">{{ activeContent.menuLinks[3].text }}</a>
        </med-text-button-group>
    </nav>

    <div class="header__actions">
        <div class="header__dropdown" (outsideClick)="isBecomeProviderSelectionOpen = false">
            <button med-text-button [btnType]="isBecomeProviderSelectionOpen ? 'primary' : 'neutral'" [btnTypography]="isSmallMonitor ? 'button-m' : 'website-subtitle-m'" [btnSuffixIcon]="isBecomeProviderSelectionOpen ? 'arrow_mini_up' : 'arrow_mini_down'" (click)="toggleBecomeProviderButton()">{{ activeContent.providerBtn }}</button>
            <div class="header__provider-popup" *ngIf="isBecomeProviderSelectionOpen">
                <div *ngFor="let option of activeContent.providerOptions" class="header__provider-content" routerLink="{{option.route}}" (click)="onProviderWindowOptionSelect()">
                    <div>
                        <span med-icon [iconName]="option.icon"></span>
                    </div>

                    <div>
                        <span med-typography textStyle="website-subtitle-l">{{option.title}}</span>
                        <span class="font-neutral-700" med-typography textStyle="body-s">{{option.description}}</span>
                    </div>
                </div>
            </div>
        </div>

        <span class="header__divider"></span>

        <div class="header__language header__dropdown">
            <button
                med-text-button 
                [btnType]="isLanguageSelectionOpen ? 'primary' : 'neutral'"
                btnTypography="button-m" 

                [btnSuffixIcon]="isLanguageSelectionOpen ? 'arrow_mini_up' : 'arrow_mini_down'" 
                medDropdown 
                [(dpdOpen)]="isLanguageSelectionOpen"
                [dpdData]="languageData"
            >
                {{ this.selectedLanguage }}
            </button>
        </div>

        <span class="header__divider"></span>

        <div class="header__buttons">
            <a href="{{providerAppUrl}}login?lang={{currentLanguage}}" target=”_blank” med-text-button btnType="primary" (click)="closeNav()">{{ activeContent.loginBtn }}</a>
            <a href="{{providerAppUrl}}signup?lang={{currentLanguage}}" target=”_blank” med-filled-button (click)="closeNav()">{{ activeContent.signupBtn }}</a>  
        </div>
    </div>
</ng-template>

<ng-template #burgerNav>
    <div class="header__navigation" (click)="openCloseNav()">
        <span class="header__open" med-icon iconName="burger-24px" *ngIf="!isNavOpen"></span>
        <span class="header__close" med-icon iconName="close" iconColor="blue-500" *ngIf="isNavOpen"></span>
    </div>
</ng-template>

<ng-container *ngIf="isNavOpen && isSmallMonitor">
    <div class="modal-background" (click)="closeNav()"></div>
    <div class="mobile__menu" [ngClass]="{'mobile__medium-menu-test': isMedium && (headerService.isTestingBar | async), 'mobile__tablet-menu-test': isTablet && (headerService.isTestingBar | async), 'mobile__mobile-menu-test': isMobile && (headerService.isTestingBar | async)}">
        <ng-container *ngTemplateOutlet="menu"></ng-container>
    </div>
</ng-container>
