import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MedFilledButtonComponent } from './med-filled-button.component';
import { MedFilledButtonGroupComponent } from './med-filled-button-group.component';
import { MedIconModule } from '../med-icon/med-icon.module';
import { MedTypographyModule } from '../med-typography/med-typography.module';
import { MedLoaderModule } from '../med-loader/med-loader.module';

@NgModule({
  declarations: [MedFilledButtonComponent, MedFilledButtonGroupComponent],
  imports: [CommonModule, MedTypographyModule, MedIconModule, MedLoaderModule],
  exports: [MedFilledButtonComponent, MedFilledButtonGroupComponent],
})
export class MedFilledButtonModule {}
