import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

export type MenuLinkType = 'platform' | 'website';

@Component({
  selector: 'med-text-button-group',
  exportAs: 'medtextButtonGroup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  template: `
    <ul class="med-text-btn-group" [class.med-menu-wrapper]="isMenuLink === true">
      <ng-content></ng-content>
    </ul>
  `,
  styleUrls: ['./med-text-button.component.scss'],
})
export class MedTextButtonGroupComponent implements OnInit {
  @Input() menuLinkType: MenuLinkType = 'platform';
  @Input() isMenuLink = false;
  private menuItems?: HTMLElement[];

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.isMenuLink) {
      this.menuItems = this.elementRef.nativeElement.querySelectorAll('.med-text-btn');

      this.menuItems?.forEach((menuItem: HTMLElement, index: number) => {
        const listElement = this.renderer.createElement('li');
        this.renderer.addClass(listElement, 'med-menu-link');
        this.renderer.insertBefore(menuItem.parentNode, listElement, this.menuItems?.[index]);
        this.renderer.appendChild(listElement, this.menuItems?.[index]);
        this.renderer.addClass(this.menuItems?.[index], `med-menu-item-${this.menuLinkType}`);
      });
    }
  }
}
