import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HEADER_DATA } from './data/header.data';
import { animate, style, transition, trigger } from '@angular/animations';
import { MedBreakpointService } from 'src/app/common/med-components/med-breakpoint/med-breakpoint.service';
import { DisplayType } from 'src/app/common/med-components/med-breakpoint/types';
import { DropdownData } from 'src/app/common/med-components/med-dropdown/med-dropdown.types';
import { MedContentLocalization, LocalizationType } from 'src/app/common/models/med-localization.service.types';
import { HeaderService } from 'src/app/common/services/header.service';
import { MedLocalizationService } from 'src/app/common/services/med-localization.service';
import { environment } from 'src/environments/environment';
import { HeaderData } from './interfaces/header.interface';

@Component({
  selector: 'med-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInOutAnimation', [
      transition(
        'false => true',
        [style({ opacity: 0, top: -104 }), animate('.5s ease', style({ opacity: 1, top: '{{topPosition}}' }))],
        { params: { topPosition: '0' } },
      ),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  content: MedContentLocalization<HeaderData> = HEADER_DATA;
  activeContent: HeaderData = this.content.en;

  isSmallMonitor?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
  isMedium?: boolean;
  isNavOpen?: boolean;
  isLanguageSelectionOpen = false;
  isBecomeProviderSelectionOpen = false;

  currentLanguage?: LocalizationType;
  selectedLanguage = 'Eng';

  languageData: DropdownData[] = [
    { text: 'Deutsch', checked: false, actions: () => this.setSelectedLanguage('de') },
    { text: 'English', checked: true, actions: () => this.setSelectedLanguage('en') },
  ];

  providerAppUrl = environment.PROVIDER_APP_URL;

  @HostListener('window:scroll') OnWindowScroll(): void {
    this.headerService.isHeaderSticky.next(window.scrollY > 120);
    if (!this.isMobile) {
      this.isNavOpen = false;
      this.isBecomeProviderSelectionOpen = false;
    }
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly locService: MedLocalizationService,
    private breakpointService: MedBreakpointService,
    public headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.locService.localizationSubject.pipe(takeUntil(this.destroy$)).subscribe((lang: LocalizationType) => {
      this.currentLanguage = lang;
      this.activeContent = this.content[lang];
    });

    this.breakpointService.displayType.pipe(takeUntil(this.destroy$)).subscribe((display: DisplayType) => {
      this.isMobile = display === 'xsmall';
      this.isTablet = display === 'small';
      this.isMedium = display === 'medium';
      this.isSmallMonitor = display === 'xsmall' || display === 'medium' || display === 'small';
    });

    // Previous version
    // this.initializeLanguage()

    this.initializeLanguage(this.currentLanguage ?? 'en');
  }

  openCloseNav(): void {
    this.isNavOpen = !this.isNavOpen;
  }

  closeNav(): void {
    this.isNavOpen = false;
  }

  toggleBecomeProviderButton(): void {
    this.isBecomeProviderSelectionOpen = !this.isBecomeProviderSelectionOpen;
  }

  onProviderWindowOptionSelect(): void {
    console.log('option Selected');
    this.closeNav();
    this.toggleBecomeProviderButton();
  }

  setSelectedLanguage(language: LocalizationType): void {
    this.locService.setLocalization(language);
    this.selectedLanguage = language === 'de' ? 'Deu' : 'Eng';
    this.closeNav();
  }

  // Previous version

  // initializeLanguage(): void {
  // this.setSelectedLanguage((localStorage.getItem('localization') ?? 'en') as LocalizationType);
  // this.languageData.map(
  //     (lang) => (lang.checked = this.selectedLanguage === lang.text.substring(0, 3) ? true : false),
  //   );
  // }

  initializeLanguage(language: LocalizationType): void {
    this.setSelectedLanguage(language);
    this.languageData.map(
      (lang) => (lang.checked = this.selectedLanguage === lang.text.substring(0, 3) ? true : false),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
