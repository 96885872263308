import { Component, Input, ViewEncapsulation } from '@angular/core';

export type MedTypographyType =
  | 'heading-l'
  | 'heading-m'
  | 'heading-s'
  | 'subtitle-l'
  | 'subtitle-m'
  | 'subtitle-s'
  | 'subtitle-xs'
  | 'body-l'
  | 'body-m'
  | 'body-s'
  | 'body-xs'
  | 'button-l'
  | 'button-m'
  | 'website-heading-l'
  | 'website-heading-m'
  | 'website-heading-s'
  | 'website-heading-xs'
  | 'website-title-extrabold'
  | 'website-title-medium'
  | 'website-subtitle-l'
  | 'website-subtitle-m'
  | 'website-subtitle-s'
  | 'website-body-l'
  | 'website-body-m'
  | 'website-body-s';

@Component({
  selector: '[med-typography]',
  exportAs: 'medTypography',
  encapsulation: ViewEncapsulation.None,
  template: ` <ng-content></ng-content> `,
  host: {
    class: 'med-text',
    '[class.med-text-heading-l]': `textStyle === 'heading-l'`,
    '[class.med-text-heading-m]': `textStyle === 'heading-m'`,
    '[class.med-text-heading-s]': `textStyle === 'heading-s'`,
    '[class.med-text-subtitle-l]': `textStyle === 'subtitle-l'`,
    '[class.med-text-subtitle-m]': `textStyle === 'subtitle-m'`,
    '[class.med-text-subtitle-s]': `textStyle === 'subtitle-s'`,
    '[class.med-text-subtitle-xs]': `textStyle === 'subtitle-xs'`,
    '[class.med-text-body-l]': `textStyle === 'body-l'`,
    '[class.med-text-body-m]': `textStyle === 'body-m'`,
    '[class.med-text-body-s]': `textStyle === 'body-s'`,
    '[class.med-text-body-xs]': `textStyle === 'body-xs'`,
    '[class.med-text-button-l]': `textStyle === 'button-l'`,
    '[class.med-text-button-m]': `textStyle === 'button-m'`,
    '[class.med-text-website-heading-l]': `textStyle === 'website-heading-l'`,
    '[class.med-text-website-heading-m]': `textStyle === 'website-heading-m'`,
    '[class.med-text-website-heading-s]': `textStyle === 'website-heading-s'`,
    '[class.med-text-website-heading-xs]': `textStyle === 'website-heading-xs'`,
    '[class.med-text-website-title-extrabold]': `textStyle === 'website-title-extrabold'`,
    '[class.med-text-website-title-medium]': `textStyle === 'website-title-medium'`,
    '[class.med-text-website-subtitle-l]': `textStyle === 'website-subtitle-l'`,
    '[class.med-text-website-subtitle-m]': `textStyle === 'website-subtitle-m'`,
    '[class.med-text-website-subtitle-s]': `textStyle === 'website-subtitle-s'`,
    '[class.med-text-website-body-l]': `textStyle === 'website-body-l'`,
    '[class.med-text-website-body-m]': `textStyle === 'website-body-m'`,
    '[class.med-text-website-body-s]': `textStyle === 'website-body-s'`,
  },
  styleUrls: ['./med-typography.component.scss'],
})
export class MedTypographyComponent {
  @Input() textStyle: MedTypographyType = 'body-m';
}
