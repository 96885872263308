import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MedDropdownComponent } from './med-dropdown.component';
import { MedDropdownDirective } from './med-dropdown.directive';
import { MedIconModule } from '../med-icon/med-icon.module';
import { MedTypographyModule } from '../med-typography/med-typography.module';

@NgModule({
  declarations: [MedDropdownComponent, MedDropdownDirective],
  imports: [CommonModule, MedTypographyModule, MedIconModule],
  exports: [MedDropdownDirective, MedDropdownComponent],
})
export class MedDropdownModule {}
