import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  document: Document;

  constructor(
    @Inject(DOCUMENT) public angularDocument: Document,
    @Inject(PLATFORM_ID) public readonly platformId: object,
    private titleService: Title,
    private meta: Meta,
    private router: Router,
  ) {
    this.document = isPlatformServer(this.platformId) ? this.angularDocument : document;
  }

  setCanonicalUrl(): void {
    const linkRel = this.document.querySelector('link[rel="canonical"]');
    const url = environment.MAIN_APP_URL + this.router.url.substring(1).split('?')[0];
    linkRel?.setAttribute('href', url);
  }

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  setMetaDescription(description: string): void {
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
  }

  setMetaData(title: string, description: string): void {
    this.setTitle(title);
    this.setMetaDescription(description);
  }
}
