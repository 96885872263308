import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { IDetectLocaleResponse, LocalizationType } from '../models/med-localization.service.types';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class MedLocalizationService {
  localizationSubject: BehaviorSubject<LocalizationType> = new BehaviorSubject<LocalizationType>('de');

  constructor(private http: HttpClient) {
    this.getLocalization().subscribe((detectedLocale) => {
      this.setLocalization((localStorage.getItem('localization') ?? detectedLocale.locale) as LocalizationType);
    })
  }

  getLocalization(): Observable<IDetectLocaleResponse> {
      return this.http.get<IDetectLocaleResponse>(`${environment.API_URL}locale/detect`).pipe(
      catchError((error) => {
        return throwError(() => new Error(error.message));
      }),
    )
  }

  setLocalization(lang: LocalizationType): void {
    localStorage.setItem('localization', lang);
    this.localizationSubject.next(lang);
  }

  /**
   * Calls the localizeObjectWorker function to recursively localize a deep copied version
   * of the data object and return it
   *
   * @param {any} data - The object or array to localize.
   * @param {'de' | 'en'} lang - The language to localize the object or array to.
   * @returns {any} - The localized object or array.
   */
  localizeObject(data: any, lang: LocalizationType): any {
    const deepCopy = JSON.parse(JSON.stringify(data));
    return this.localizeObjectWorker(deepCopy, lang);
  }

  /**
   * Recursively localizes an object by returning the value associated with the provided language
   * or by localizing each nested object or array.
   *
   * @param {any} data - The object or array to localize.
   * @param {'de' | 'en'} lang - The language to localize the object or array to.
   * @returns {any} - The localized object or array.
   */
  localizeObjectWorker(data: any, lang: LocalizationType): any {
    // If the data is an array, recursively call the function on each element of the array
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        data[i] = this.localizeObject(data[i], lang);
      }
      return data;
    }
    // If the data is an object, localize each key-value pair in the object
    else if (typeof data === 'object' && data !== null) {
      const localizedObject: { [key: string]: any } = {};
      for (const key of Object.keys(data)) {
        const value = data[key];
        // If the key matches the provided language, return the value associated with that key
        if (key === lang) {
          return value;
        }
        // Otherwise, recursively call the function on the nested object or array and add it to the localized object
        else {
          localizedObject[key] = this.localizeObject(value, lang);
        }
      }
      return localizedObject;
    }
    // If the data is a string, number, or boolean value, return it unchanged
    else {
      return data;
    }
  }
}
