import { Component, ElementRef, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { DropdownInfoText, DropdownData } from './med-dropdown.types';
import { MedDropdownDirective } from './med-dropdown.directive';
import { MedIconName } from '../med-icon/med-icon.types';
import { MedTypographyType } from '../med-typography/med-typography.component';

@Component({
  selector: 'med-dropdown-menu',
  template: `
    <div class="dropdown-container" med-typography [textStyle]="setTypography()" *ngIf="this.isVisible">
      <ul>
        <ng-container *ngIf="dpdData.length < 1">
          <li class="info-message" *ngIf="dropdownInfoText?.notFoundMessage">{{ dropdownInfoText.notFoundMessage }}</li>
        </ng-container>
        <ng-container *ngIf="dpdData && dpdData.length > 0">
          <li class="info-message" *ngIf="dropdownInfoText?.infoMessage">{{ dropdownInfoText.infoMessage }}</li>
          <ng-container *ngFor="let data of dpdData; index as index">
            <li class="alphabetical-order-group" *ngIf="dpdSort === 'alphabetical' && isNextAlphabetical(index)">
              {{ toCapitalLetter(data.text[0]) }}
            </li>
            <li>
              <a [class.selected]="data?.checked" (click)="onDropdownItemClick(data)">
                <span class="dropdown-prefix-icon" med-icon [iconName]="data?.prefix!" *ngIf="data?.prefix"></span>
                <div class="dropdown-text-wrapper">
                  <span class="dropdown-text">{{ data?.text }}</span>
                  <span class="dropdown-subtext" *ngIf="data?.subText">{{ data?.subText }}</span>
                </div>
                <span
                  class="dropdown-suffix-icon"
                  med-icon
                  [iconName]="checkedSuffixIcon"
                  *ngIf="data?.checked === true"
                ></span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
    <ng-content></ng-content>
  `,
  host: {
    class: 'med-dropdown-menu',
  },
  styleUrls: ['med-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MedDropdownComponent {
  dpdData: DropdownData[] = [];
  dpdSort?: 'alphabetical' | 'simple';
  dpdMultipleSelect?: boolean;

  private _cursorActive = false;
  get cursorActive(): boolean {
    return this._cursorActive;
  }

  directive!: MedDropdownDirective;

  onDropDownItemSelection: EventEmitter<DropdownData[]> = new EventEmitter<DropdownData[]>();
  onDropDownItemChange: EventEmitter<DropdownData> = new EventEmitter<DropdownData>();

  isVisible?: boolean;
  checkedSuffixIcon: MedIconName = 'checkmark';
  dropdownInfoText: DropdownInfoText = { notFoundMessage: '', infoMessage: '' };

  /* hide dropdown component */
  @HostListener('mouseenter', ['$event']) onMouseEnter() {
    this._cursorActive = true;
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this._cursorActive = false;
  }

  constructor(public elementRef: ElementRef) {}

  onDropdownItemClick(data: DropdownData) {
    if (data.actions) {
      data.actions();
    }
    // Multi-Select
    if (this.dpdMultipleSelect) {
      data.checked = !data.checked;
    }
    // Single-Select
    else {
      this.dpdData.forEach((item: DropdownData) => {
        item.checked = false;
      });
      data.checked = true;
    }
    this.onDropDownItemSelection.emit(this.dpdData);
    this.onDropDownItemChange.emit(data);
    if (!this.dpdMultipleSelect) {
      this._cursorActive = false;
      this.isVisible = false;
      this.directive?.dpdOpenChange.emit(this.isVisible);
    }
  }

  isNextAlphabetical(index: number): boolean {
    if (index == 0) {
      return true;
    } else if (this.dpdData.length >= index) {
      if (this.dpdData[index].text.charAt(0) != this.dpdData[index - 1].text.charAt(0)) {
        return true;
      }
    }
    return false;
  }

  toCapitalLetter(letter: string): string {
    return letter.toLocaleUpperCase();
  }

  setTypography(): MedTypographyType {
    return 'body-m';
  }
}
