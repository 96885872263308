import {
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { MedIconName, MedIconSize } from '../med-icon/med-icon.types';
import { MedTypographyType } from '../med-typography/med-typography.component';

export type MedFilledButtonType = 'primary' | 'warn' | 'default' | 'neutral' | 'white' | null;
export type MedFilledButtonSize = 'large' | 'normal' | 'big';
export type MedFilledButtonState = 'normal' | 'loading' | 'rest';

@Component({
  selector: 'button[med-filled-button], a[med-filled-button]',
  exportAs: 'medFilledButton',
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-container *ngIf="btnState === 'normal' || disabled">
      <ng-container *ngIf="btnPrefixIcon">
        <span
          med-icon
          class="prefix-icon"
          [class.icon-blue-500]="btnType === 'default' || btnType === 'neutral'"
          [class.icon-white]="btnType === 'primary'"
          [class.icon-red-500]="btnType === 'warn'"
          [iconName]="btnPrefixIcon"
          [iconSize]="btnIconSize"
        >
        </span>
      </ng-container>
      <span med-typography [textStyle]="setTypography()"><ng-content></ng-content></span>
      <ng-container *ngIf="btnSuffixIcon">
        <span
          med-icon
          class="suffix-icon"
          [class.icon-blue-500]="btnType === 'default' || btnType === 'neutral'"
          [class.icon-white]="btnType === 'primary'"
          [class.icon-red-500]="btnType === 'warn'"
          [iconName]="btnSuffixIcon"
          [iconSize]="btnIconSize"
        >
        </span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="btnState === 'loading' && !disabled">
      <med-loader [loaderSize]="24" [trailWidth]="8" trailType="white"></med-loader>
    </ng-container>
  `,
  host: {
    class: 'med-btn',
    '[class.med-btn-primary]': `btnType === 'primary'`,
    '[class.med-btn-warn]': `btnType === 'warn'`,
    '[class.med-btn-default]': `btnType === 'default'`,
    '[class.med-btn-neutral]': `btnType === 'neutral'`,
    '[class.med-btn-white]': `btnType === 'white'`,
    '[class.med-btn-large]': `btnSize === 'large'`,
    '[class.med-btn-big]': `btnSize === 'big'`,
    '[class.med-btn-icon]': `btnPrefixIcon || btnSuffixIcon`,
    '[attr.tabindex]': 'disabled ? -1 : (tabIndex === null ? null : tabIndex)',
    '[attr.disabled]': 'disabled || null',
  },
  styleUrls: ['./med-filled-button.component.scss'],
})
export class MedFilledButtonComponent implements OnInit, OnDestroy {
  @Input() btnType: MedFilledButtonType = 'default';
  @Input() btnSize: MedFilledButtonSize = 'normal';
  @Input() btnPrefixIcon: MedIconName | null = null;
  @Input() btnSuffixIcon: MedIconName | null = null;
  @Input() btnIconSize: MedIconSize = null;
  @Input() btnState: MedFilledButtonState = 'normal';
  @Input() disabled = false;
  @Input() tabIndex: number | string | null = 0;

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter' && (event.target as HTMLElement).localName == 'a') {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.target?.dispatchEvent(new Event('click'));
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent) {
    if (this.btnState === 'loading') {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private destroy$ = new Subject<void>();

  constructor(private ngZone: NgZone, private elementRef: ElementRef) {}

  ngOnInit() {
    // To apply prevent-default() and stop-propagation() when the element is disabled
    this.ngZone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(this.elementRef.nativeElement, 'click', { capture: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTypography(): MedTypographyType {
    return 'button-l';
  }
}
